<template>
	<app v-if="mobile" class="mobile" tabbar>
		<ul class="backlog flex-wrap">
			<li v-for="(item, i) in backlogList" :key="i" @click="linkHandle(item.unique_key)">
				<strong :class="{'z-dis': item.count == 0}">{{item.count}}</strong>
				<p>{{item.name}}</p>
			</li>
		</ul>
		
		<div class="realtime">
			<div class="title flex-center-cross"><i class="tf">&#xe722;</i>实时数据</div>
			<ul class="total flex-wrap">
				<li v-for="(item, i) in totalData" :key="i">
					<p>{{item.name}}</p>
					<strong>{{item.count}}</strong>
				</li>
			</ul>
			
			<div class="chart-box">
				<div class="chart">
					<chart v-if="chartData" type="bar" :data="chartData" height="375"></chart>
				</div>
			</div>
		</div>
	</app>
	<page v-else class="pc">
		<module title="待办事项" height="auto">
			<ul class="backlog flex-wrap">
				<li v-for="(item, i) in backlogList" :key="i" @click="linkHandle(item.unique_key)">
					<strong :class="{'z-dis': item.count == 0}">{{item.count}}</strong>
					<p>{{item.name}}</p>
				</li>
			</ul>
		</module>
		<module title="实时数据">
			<ul class="total flex-wrap">
				<li v-for="(item, i) in totalData" :key="i">
					<div class="item">
						<p>{{item.name}}</p>
						<strong>{{item.count}}</strong>
					</div>
				</li>
			</ul>
			
			<chart v-if="chartData" type="bar" :data="chartData" height="375"></chart>
		</module>
	</page>
</template>

<script>
	import chart from '@/components/charts/index.vue'
	import {mapState} from 'vuex'
	
	export default {
		name: 'home',
		
		components: {
			chart
		},
		
		data() {
			return {
				backlogList: [],
				totalData: [],
				chartData: ''
			};
		},
		
		computed: {
			...mapState(['mobile'])
		},
		
		mounted() {
			this.updateData();
		},
		
		methods: {
			updateData() {
				this.request({
					url: '/workbench/stayitem_list',
					success: data => {
						this.backlogList = data;
					}
				});
				
				this.request({
					url: '/workbench/survey_list',
					success: data => {
						this.totalData = data;
					}
				});
				
				this.request({
					url: '/workbench/flow_chart_data',
					success: data => {
						this.chartData = [{
							type: '有效保函数',
							data: Array.from(data, item => {
								return {
									name: item.title,
									value: item.yxCount
								}
							})
						}, {
							type: '生效保函数',
							data: Array.from(data, item => {
								return {
									name: item.title,
									value: item.sxzCount
								}
							})
						}];
					}
				});
			},
			
			linkHandle(key) {
				switch (key) {
				case 'm_project_approve':
					this.navigateTo(this.mobile ? '/project_list' : '/project_approve');
					break;
					
				case 'm_project':
					this.navigateTo(this.mobile ? '/project_list?tab=1' : '/project_list');
					break;
					
				case 'm_ht':
					this.navigateTo('/contract_list');
					break;
					
				case 'm_kgl':
					this.navigateTo(this.mobile ? '/proceed_list' : '/proceed_approve');
					break;
					
				case 'm_ry':
					this.navigateTo(this.mobile ? '/project_list?tab=2' : '/person_change');
					break;
					
				case 'm_aqtz':
					this.navigateTo(this.mobile ? '/ledger_list' : '/ledger_approve');
					break;
				}
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.pc {
		.backlog {
			padding: 0 30px 24px;
			margin-left: -24px;
			
			li {
				box-sizing: border-box;
				width: 250px;
				height: 110px;
				padding-top: 26px;
				margin: 24px 0 0 24px;
				text-align: center;
				background: #F8F8F8;
				cursor: pointer;
				
				&:hover {
					background: #3296FA;
					
					strong, p {
						color: #fff !important;
					}
				}
				
				strong {
					font-size: 30px;
					color: #000;
					
					&.z-dis {
						color: #666;
					}
				}
				
				p {
					margin-top: 14px;
					font-size: 14px;
					color: #666;
				}
			}
		}
		
		.total {
			padding: 0 30px 24px;
			margin-left: -22px;
			
			li {
				box-sizing: border-box;
				width: 25%;
				padding-left: 22px;
				margin-top: 24px;
				
				.item {
					box-sizing: border-box;
					height: 110px;
					padding: 24px 20px 0;
					background: #F8F8F8;
					
					p {
						margin-bottom: 20px;
						font-size: 15px;
						color: #000;
					}
					
					strong {
						font-size: 30px;
						color: #000;
					}
				}
			}
		}
	}
	
	.mobile {
		.backlog {
			padding-bottom: .1rem;
			color: #fff;
			background: #3296FA;
			
			li {
				box-sizing: border-box;
				width: 2.5rem;
				height: 1.6rem;
				padding-top: .34rem;
				text-align: center;
				
				strong {
					font-size: .44rem;
				}
				
				p {
					margin-top: .24rem;
					font-size: .26rem;
					opacity: .7;
				}
			}
		}
		
		.realtime {
			margin-top: .2rem;
			background: #fff;
			
			.title {
				height: 1rem;
				border-bottom: 1px solid #eee;
				padding: 0 .32rem;
				font-size: .32rem;
				color: #333;
				
				.tf {
					margin-right: .16rem;
					font-size: .32rem;
					color: #FF9F00;
				}
			}
			
			.total {
				padding: .25rem .32rem;
				
				li {
					box-sizing: border-box;
					width: 3.36rem;
					height: 1.88rem;
					padding: .44rem .16rem 0 .25rem;
					background: #F8F8F8;
					
					&:nth-child(n+3) {
						margin-top: .14rem;
					}
					
					&:nth-child(even) {
						margin-left: .14rem;
					}
					
					p {
						margin-bottom: .3rem;
						font-size: .28rem;
						color: #666;
					}
					
					strong {
						font-size: .52rem;
						color: #000;
					}
				}
			}
			
			.chart {
				width: 13rem;
				
				&-box {
					overflow: auto;
				}
			}
		}
	}
</style>