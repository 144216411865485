<template>
    <div ref="box" class="box">
        <div ref="charts" :style="{'height': parsePixel(height)}"></div>
    </div>
</template>

<script>
    import echarts from 'echarts'

    export default {
        props: {
            type: { // 图标类型（line：折线图；bar：柱状体；pie：饼图）
                type: String,
                required: true
            },

            height: [String, Number],

            data: {
                type: Array,
                default() {
                    return []
                }
            },
            typeField: {
                type: String,
                default: 'type'
            },
            dataField: {
                type: String,
                default: 'data'
            },
            nameField: {
                type: String,
                default: 'name'
            },
            valueField: {
                type: String,
                default: 'value'
            }
        },

        watch: {
            data() {
                this.update();
            }
        },

        mounted() {
            this.echartsObj = echarts.init(this.$refs.charts);

            this.update();
            window.addEventListener('resize', this.resize);
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.resize);
        },

        methods: {
            update() {
                this.echartsObj.setOption({
                    color: ['#1492FF', '#E63633', '#F1960D', '#00B578', '#FF19CE'],

                    grid: {
                        top: 40,  // 图表底部距离
                        bottom: 70,  // 图表底部距离
                        left: 50,  // 图表左边距离
                        right: 30  // 图表右边距离
                    },

                    legend: {
                        orient: this.type == 'pie' ? 'vertical' : 'horizontal', // 图片排列方式（horizontal：水平；vertical：垂直）
                        left: this.type == 'pie' ? '10%' : 'center',
                        bottom: this.type == 'pie' ? 'center' : 15
                    },

                    tooltip: {
                        trigger: this.type == 'pie' ? 'item' : 'axis',  // 提示框（item：坐标点触发；axis：x轴刻度触发；）
                        formatter: this.type == 'pie' ? '{b} : {c} ({d}%)' : ''
                    },

                    xAxis: {
                        show: this.type != 'pie',
                        axisLine: {
                            lineStyle: {
                                color: '#999'  // x轴轴线颜色
                            }
                        },
                        axisTick: {
                            lineStyle: {
                                color: '#999'  // x轴刻度线颜色
                            }
                        },
                        boundaryGap: this.type == 'bar',  // x轴两端留白
                        splitLine: {
                            show: false  // x轴分割线
                        },
                        data: this.type != 'pie' ? (this.data[0] ? Array.from(this.data[0][this.dataField], item => {
                            return item[this.nameField];
                        }) : []) : []
                    },

                    yAxis: {
                        show: this.type != 'pie',
                        axisLine: {
                            show: true,  // 是否显示y轴轴线
                            lineStyle: {
                                color: '#999'  // y轴轴线颜色
                            }
                        },
                        axisTick: {
                            lineStyle: {
                                color: '#999'  // y轴刻度线颜色
                            }
                        },
                        splitLine: {
                            show: false  // y轴分割线
                        }
                    },

                    series: this.type == 'pie' ? [{
                        type: 'pie',
                        radius: '60%',  // 饼图比例
                        center: ['60%', '50%'],  // 饼图圆点位置
                        data: Array.from(this.data, item => {
                            return {
                                value: item[this.valueField],
                                name: item[this.nameField],
                            }
                        })
                    }] : Array.from(this.data, item => {
                        return {
                            name: item[this.typeField],
                            type: this.type,
                            smooth: true, // 折线平滑
                            areaStyle: true, // 折线面积
                            barWidth: this.$store.state.mobile ? 20 : 60,  // 柱状宽度
                            data: Array.from(item[this.dataField], item => {
                                return item[this.valueField];
                            })
                        }
                    })
                });
            },

            resize() {
                this.echartsObj.resize({
                    width: this.$refs.box.clientWidth
                });
            }
        }
    }
</script>
